import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert, Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useLayoutEffect, useRef, useState } from "react";
import clsx from "clsx";
import { MdExpandMore } from "react-icons/md";
import { Breadcrumbs } from "./Breadcrumbs";
import { PlayButton } from "./PlayButton";
import { DownloadButton } from "./DownloadButton";
import { ShareButton } from "./ShareButton";
import { AddToPlaylistButton } from "./AddToPlaylistButton";
import { RemoveFromPlaylistButton } from "./RemoveFromPlaylistButton";
import styles from "./styles/Episode.module.css";
import { SimilarPodcasts } from "./SimilarPodcasts";
import { Paper } from "./Paper";
import { createDurationMin, filterText, filterTitle, getEpisodeImage, getFirstCategory, timeAgo } from "@/core/helpers";
import { useStores } from "@/hooks/useStores";
import { linkCategories } from "@/core/autolinking";
import { useEpisodeAds } from "@/hooks/useEpisodeAds";

const SQUARE_SIZE = 360;

interface StandaloneEpisodeProps {
    episode: Episode;
    podcast: Podcast;
}

export const StandaloneEpisode = observer(({ episode, podcast }: StandaloneEpisodeProps) => {
    const ad = useEpisodeAds(podcast.categories);
    const { podcastStore, playlistStore, categoryStore } = useStores();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [useToggle, setUseToggle] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const isVideo = episode.sound.endsWith(".mp4");
    const descriptionRef = useRef<HTMLDivElement>(null);
    const maxHeight = 260;

    const isPreloading = useRef<boolean>(false);

    const isFotboll = podcast?.categories.some((c) => c.slug === "fotboll");

    if (!isPreloading.current) {
        isPreloading.current = true;
        const src = getEpisodeImage(episode, podcast);
        const img = new Image();
        img.onload = () => {
            setTimeout(() => {
                setImageLoaded(true);
            }, 0);
        };
        img.src = src;
    }

    useLayoutEffect(() => {
        if (descriptionRef.current) {
            const height = descriptionRef.current.offsetHeight;
            if (height > maxHeight) {
                setUseToggle(true);
            }
        }
    }, []);

    const contentCls = clsx(
        styles.content,
        useToggle && {
            [styles.short]: !isExpanded,
        }
    );

    return (
        <>
            <Helmet>
                <link rel="preload" as="image" href={getEpisodeImage(episode, podcast)} />
                <script type={"application/ld+json"}>{`
                        {
                            "@context": "https://schema.org",
                            "@type": "PodcastEpisode",
                            "url": "https://hittapodd.se/podd/${podcast.slug}/${episode.slug}",
                            "name": "${episode.title}", 
                            "datePublished": "${episode.published.substring(0, 10)}",
                            "timeRequired": "PT${createDurationMin(episode.length)}M",
                            "description": "${episode.snippet}",
                            "associatedMedia": {
                                "@type": "MediaObject",
                                "contentUrl": "${episode.sound}"
                            },
                            "partOfSeries": {
                                "@type": "PodcastSeries",
                                "name": "${podcast.name}",
                                "url": "https://hittapodd.se/podd/${podcast.slug}"
                             }
                        }
                    `}</script>
            </Helmet>

            <Breadcrumbs
                items={[
                    {
                        label: "Kategorier",
                        link: "/kategori",
                    },
                    {
                        ...getFirstCategory(podcast.categories),
                    },
                    {
                        label: podcast.name,
                        link: `/podd/${podcast.slug}`,
                    },
                    {
                        active: true,
                        label: episode.title,
                        link: `/podd/${podcast.slug}/${episode.slug}`,
                    },
                ]}
            />
            <h1
                className={styles.header}
                dangerouslySetInnerHTML={{
                    __html: filterTitle(episode.title),
                }}
            />
            <h2 className={styles.subheader}>
                <Link className={styles.podname} to={"/podd/" + podcast.slug}>
                    {podcast.name}
                </Link>
            </h2>
            <Paper>
                <Row>
                    <Col md={4}>
                        {!isVideo && imageLoaded && (
                            <>
                                <div className={styles.img}>
                                    <img
                                        className={"img-fluid"}
                                        loading={"eager"}
                                        alt={podcast.name}
                                        src={getEpisodeImage(episode, podcast)}
                                        width={SQUARE_SIZE}
                                        height={SQUARE_SIZE}
                                        title={podcast.name + " - " + episode.title}
                                    />
                                    <div className={styles.play}>
                                        <PlayButton
                                            size={"large"}
                                            key={episode.guid}
                                            podcast={podcast}
                                            episode={episode}
                                        />
                                    </div>
                                </div>
                                <Alert
                                    variant={"info"}
                                    style={{
                                        fontSize: 14,
                                    }}>
                                    Alla bilder och innehåll är syndikerat från {podcast.name}{" "}
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={podcast.feed}>
                                        RSS-feed
                                    </a>{" "}
                                    som ägs och drivs av {podcast.author.name}.{" "}
                                </Alert>
                            </>
                        )}
                        {!imageLoaded && (
                            <div className={styles.img}>
                                <div className={styles.placeholder}>&nbsp;</div>
                            </div>
                        )}
                    </Col>
                    <Col md={8}>
                        {episode.subjects && episode.subjects.length > 0 && (
                            <div className={styles.focus}>
                                <strong>Fokus:</strong>{" "}
                                {episode.subjects.map((subject: Subject) => {
                                    return (
                                        <Link key={`subject-${subject.slug}`} to={`/fokus/${subject.slug}`}>
                                            {subject.name}
                                        </Link>
                                    );
                                })}
                            </div>
                        )}

                        <time className={styles.time} dateTime={episode.published}>
                            Publicerad: {episode.published.substring(0, 10)} - {timeAgo(episode.published)} - Längd:{" "}
                            {createDurationMin(episode.length)} minuter
                        </time>

                        {episode.content.length > 0 && (
                            <div ref={descriptionRef}>
                                <div
                                    className={contentCls}
                                    dangerouslySetInnerHTML={{
                                        __html: linkCategories(filterText(episode.content), categoryStore.categoryMap),
                                    }}
                                />
                                {useToggle && (
                                    <p className={styles.toggle}>
                                        <a
                                            href={"#toggle"}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setIsExpanded(!isExpanded);
                                            }}>
                                            {isExpanded ? (
                                                <>
                                                    <MdExpandMore className={styles.flip} /> Visa mindre
                                                </>
                                            ) : (
                                                <>
                                                    <MdExpandMore /> Visa mer
                                                </>
                                            )}
                                        </a>
                                    </p>
                                )}
                            </div>
                        )}
                        <div className={styles.actions}>
                            {!isVideo && <PlayButton episode={episode} podcast={podcast} labels={["Spela", "Pausa"]} />}
                            {!playlistStore.inList(episode) && (
                                <AddToPlaylistButton episode={episode} podcast={podcast} />
                            )}
                            {playlistStore.inList(episode) && (
                                <RemoveFromPlaylistButton episode={episode} podcast={podcast} />
                            )}
                            <DownloadButton episode={episode} />
                            <ShareButton episode={episode} podcast={podcast} />
                        </div>

                        {ad ? (
                            <a target={"_blank"} rel={"noreferrer noopener"} href={ad.url} className={styles.episodeAd}>
                                <h5>{ad.title}</h5>
                                <p>{ad.description}</p>
                                <img width={120} src={ad.image} alt={ad.title} />
                            </a>
                        ) : null}
                        {isFotboll ? (
                            <p>
                                Statistik om Allsvenskan, Superettan mfl hittar du på{" "}
                                <a
                                    target={"_blank"}
                                    rel={"noopener noreferrer dofollow"}
                                    href={
                                        "https://fotbollsfeber.se?utm_source=hittapodd&utm_medium=link&utm_campaign=episode"
                                    }>
                                    Fotbollsfeber.se
                                </a>
                            </p>
                        ) : null}
                    </Col>
                </Row>

                {isVideo && (
                    <video controls className={styles.video}>
                        <source src={episode.sound} type="video/mp4" />
                    </video>
                )}
            </Paper>

            <div className={styles.paging}>
                {podcastStore.previous && (
                    <Link
                        title={podcastStore.previous.title}
                        to={`/podd/${podcast.slug}/${podcastStore.previous.slug}`}>
                        <GrFormPrevious />
                        <span className={styles.truncate}>{filterTitle(podcastStore.previous.title)}</span>
                    </Link>
                )}
                {podcastStore.next && (
                    <Link title={podcastStore.next.title} to={`/podd/${podcast.slug}/${podcastStore.next.slug}`}>
                        <span className={styles.truncate}>{filterTitle(podcastStore.next.title)}</span> <GrFormNext />
                    </Link>
                )}
            </div>

            <SimilarPodcasts podcast={podcast} />
        </>
    );
});
