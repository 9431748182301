import { Outlet } from "react-router-dom";
import { Player } from "./Player";
import { PlaylistModal } from "./PlaylistModal";
import styles from "./styles/Layout.module.css";

export const Layout = () => {
    return (
        <div className={"main " + styles.main}>
            <div className="container">
                <div className={styles.push}>
                    <a
                        rel={"noreferrer noopener"}
                        href="https://ion.bookbeat.com/t/t?a=1811596511&as=1628518118&t=2&tk=1"
                        target="_blank">
                        <img src={"/icons/bookbeat.png"} alt="BookBeat" />
                        <div>
                            <div>Prova Bookbeat 45 dagar gratis</div>
                            <small>Hitta din nya favoritbok hos BookBeat! </small>
                        </div>
                    </a>
                    <img
                        src="https://track.adtraction.com/t/t?a=1811596511&as=1628518118&t=1&tk=1&i=1"
                        width="1"
                        height="1"
                    />
                </div>
                <Outlet />
            </div>
            <Player key="7" />
            <PlaylistModal />
        </div>
    );
};
